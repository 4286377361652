import { BrowserRouter as Router, Route, Redirect } from 'react-router-dom';
import React, { Suspense, lazy, Fragment, Component } from 'react';
import { Spinner } from 'reactstrap';
import { connect } from 'react-redux';
import { refreshToken } from '../../reducers/AdminAuth';
import Login from '../../Containers/Login';
import AppHeader from '../../Layout/AppHeader';
import AppSidebar from '../../Layout/AppSidebar';

const Dashboards = lazy(() => import('../../Containers/Dashboards'));
const Groups = lazy(() => import('../../Containers/Groups'));
const Students = lazy(() => import('../../Containers/Students'));
const Teachers = lazy(() => import('../../Containers/Teachers'));
const Assignments = lazy(() => import('../../Containers/Assignments'));
const StudyMaterial = lazy(() => import('../../Containers/StudyMaterial'));
const Streams = lazy(() => import('../../Containers/Streams'));
const Settings = lazy(() => import('../../Containers/Settings'));
const Guides = lazy(() => import('../../Containers/Guides'));
const Feedback = lazy(() => import('../../Containers/Feedback'));
const Conferences = lazy(() => import('../../Containers/Conferences'));
const Messages = lazy(() => import('../../Containers/Messages'));
const quizzes = lazy(() => import('../../Containers/Quiz'));
const reports = lazy(() => import('../../Containers/Reports'))
const updateLogo = lazy(() => import('../../Containers/UpdateLogo'))
const Doubts = lazy(() => import('../../Containers/Doubts'));

const tour = lazy(() => import('../../Containers/Guides/tour'));
const guide = lazy(() => import('../../Containers/Guides/index'));


class Main extends Component {
    componentDidMount() {
        this.props.refreshToken();
    }

    render() {
        if (!this.props.AdminAuth.loggedIn) return (<Login />);

        return (
            <Fragment>
                <div className='app-header bg-strong-bliss header-text-light header-shadow'>
                <AppHeader type="Admin" />
                </div>
                <div className="app-main">
                    <AppSidebar menuType={'admin'} />
                    <div className="app-main__outer">

                        {/* Dashboards */}
                        <Suspense fallback={
                            <div className="loader-container">
                                <Spinner size="lg" color="primary" />
                            </div>
                        }>
                            <Route path="/dashboard" component={Dashboards} />
                        </Suspense>

                        {/* Students */}
                        <Suspense fallback={
                            <div className="loader-container">
                                <Spinner size="lg" color="primary" />
                            </div>
                        }>
                            <Route path="/clients" component={Students} />
                        </Suspense>

                        {/* Teachers */}
                        <Suspense fallback={
                            <div className="loader-container">
                                <Spinner size="lg" color="primary" />
                            </div>
                        }>
                            <Route path="/teachers" component={Teachers} />
                        </Suspense>

                        {/* Groups */}
                        <Suspense fallback={
                            <div className="loader-container">
                                <Spinner size="lg" color="primary" />
                            </div>
                        }>
                            <Route path="/insurance-companies" component={Groups} />
                        </Suspense>

                        {/* Doubts */}
                        <Suspense fallback={
                            <div className="loader-container">
                                <Spinner size="lg" color="primary" />
                            </div>
                        }>
                            <Route path="/doubts" component={Doubts} />
                        </Suspense>

                        {/* Assignments */}
                        <Suspense fallback={
                            <div className="loader-container">
                                <Spinner size="lg" color="primary" />
                            </div>
                        }>
                            <Route path="/policy-ads" component={Assignments} />
                        </Suspense>


                    {/* Study Material */}
                        <Suspense fallback={
                            <div className="loader-container">
                                <Spinner size="lg" color="primary" />
                            </div>
                        }>
                            <Route path="/policy-documents" component={StudyMaterial} />
                        </Suspense>

                        {/* Streams */}
                        <Suspense fallback={
                            <div className="loader-container">
                                <Spinner size="lg" color="primary" />
                            </div>
                        }>
                            <Route path="/promo-videos" component={Streams} />
                        </Suspense>

                        {/* Quiz */}
                        <Suspense fallback={
                            <div className="loader-container">
                                <Spinner size="lg" color="primary" />
                            </div>
                        }>
                            <Route path="/news-letter" component={quizzes} />
                        </Suspense>

                        {/* Update Logo */}
                        <Suspense fallback={
                            <div className="loader-container">
                                <Spinner size="lg" color="primary" />
                            </div>
                        }>
                            <Route path="/updateLogo" component={updateLogo} />
                        </Suspense>

                        {/* Guides */}
                        <Suspense fallback={
                            <div className="loader-container">
                                <Spinner size="lg" color="primary" />
                            </div>
                        }>
                            <Route path="/tour" component={tour} />
                        </Suspense>

                        <Suspense fallback={
                            <div className="loader-container">
                                <Spinner size="lg" color="primary" />
                            </div>
                        }>
                            <Route path="/guide/:id" component={guide} />
                        </Suspense>

                        {/* Reports */}
                        <Suspense fallback={
                            <div className="loader-container">
                                <Spinner size="lg" color="primary" />
                            </div>
                        }>
                            <Route path="/reports" component={reports} />
                        </Suspense>

                        {/* Settings */}
                        <Suspense fallback={
                            <div className="loader-container">
                                <Spinner size="lg" color="primary" />
                            </div>
                        }>
                            <Route path="/settings" component={Settings} />
                        </Suspense>

                        {/* Guides */}
                        <Suspense fallback={
                            <div className="loader-container">
                                <Spinner size="lg" color="primary" />
                            </div>
                        }>
                            <Route path="/guides" component={Guides} />
                        </Suspense>

                        {/* Feedback */}
                        <Suspense fallback={
                            <div className="loader-container">
                                <Spinner size="lg" color="primary" />
                            </div>
                        }>
                            <Route path="/feedback" component={Feedback} />
                        </Suspense>

                        {/* Conferences */}
                        <Suspense fallback={
                            <div className="loader-container">
                                <Spinner size="lg" color="primary" />
                            </div>
                        }>
                            <Route path="/conferences" component={Conferences} />
                        </Suspense>

                        {/* Messages */}
                        <Suspense fallback={
                            <div className="loader-container">
                                <Spinner size="lg" color="primary" />
                            </div>
                        }>
                            <Route path="/messages" component={Messages} />
                        </Suspense>



                        <Route exact path="/" render={() => (
                            <Redirect to="/dashboard" />
                        )} />
                    </div>
                </div>
            </Fragment>
        );
    }
}

const mapStateToProp = state => ({
    AdminAuth: state.AdminAuth,
    Setup: state.Setup,
});

const mapDispatchToProps = dispatch => {
    return {
        refreshToken: () => refreshToken(dispatch),
    }
}

export default connect(mapStateToProp, mapDispatchToProps)(Main);
