export const MainNav = [
    {
        icon: 'lnr-chart-bars',
        label: 'Dashboard',
        to: '#/dashboard',
    },
    {
        icon: 'pe-7s-users',
        label: 'Insurance Companies',
        to: '#/insurance-companies',
    },
    {
        icon: 'lnr-graduation-hat',
        label: 'Clients',
        to: '#/clients',
    },
    {
        icon: 'pe-7s-notebook',
        label: 'Assign Policy',
        to: '#/policy-documents',
    },
    {
        icon: 'pe-7s-notebook',
        label: 'Policy Advt.',
        to: '#/policy-ads',
    },
    {
        icon: 'lnr-film-play',
        label: 'Promotional Videos',
        to: '#/promo-videos',
    },
    {
        icon: 'lnr-laptop-phone',
        label: 'Convene A Meet',
        to: '#/conferences',
    },
    {
        icon: 'pe-7s-notebook',
        label: 'General Notices',
        to: '#/news-letter',
    },
    {
        icon: 'lnr-envelope',
        label: 'Send Credentials',
        content: [
            {
                label: 'Clients',
                to: '#/messages/clients',
            }
        ]
    },
    {
        icon: 'pe-7s-note2',
        label: 'Tickets',
        to: '#/doubts',
    },
    {
        icon: 'lnr-envelope',
        label: 'Reminders',
        to: '#/reports',
    },
    {
        icon: 'lnr-cog',
        label: 'View Profile',
        to: '#/settings',
    },
    {
        icon: 'pe-7s-note2',
        label: 'Feedback',
        to: '#/feedback',
    },
    {
        icon: 'pe-7s-compass',
        label: 'Take A Tour',
        to: '#/tour',
    }
];
